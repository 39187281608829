<template>
  <div class="tabs-layout">
    <dynamic-tabs :activeName.sync="activeName" :tabs="tabs"></dynamic-tabs>
  </div>
</template>

<script>
import DynamicTabs from '../../../components/DynamicTabs.vue';

export default {
  name: 'TestValuationIndex',
  components: {
    DynamicTabs,
  },
  data() {
    return {
      activeName: 'wait-goods',
      tabs: [
        {
          label: '待收货',
          component: 'wait-goods',
        },
        {
          label: '已收货待检测',
          component: 'wait-check',
        },
        {
          label: '已检测待确认估值',
          component: 'confirm-valuation',
        },
        {
          label: '已确认估值待签约',
          component: 'wait-sign-up',
        },
        {
          label: '已取消质押待回寄',
          component: 'wait-return',
        },
        {
          label: '已回寄待确认到货（取消质押）',
          component: 'confirm-arrival',
        },
        {
          label: '已到货（取消质押）',
          component: 'arrived',
        },
      ],
    };
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss">
</style>
